{
  "name": "webapp-namoadigital",
  "version": "26.1.6-964f98b.0",
  "scripts": {
    "ng": "ng",
    "postinstall": "is-ci || husky install",
    "start:devlocal": "ng serve -c=devlocal --host=appdevlocal.namoa.io --ssl=true --ssl-cert=certificates/dev-local/cert.crt --ssl-key=certificates/dev-local/cert.key --open",
    "serve:devlocal": "ng serve -c=devlocal --host=appdevlocal.namoa.io --ssl=true --ssl-cert=certificates/dev-local/cert.crt --ssl-key=certificates/dev-local/cert.key --port=4201",
    "start:dev": "ng serve -c=dev --host=appdevlocal.namoa.io --ssl=true --ssl-cert=certificates/dev-local/cert.crt --ssl-key=certificates/dev-local/cert.key --open",
    "serve:dev": "ng serve -c=dev --host=appdevlocal.namoa.io --ssl=true --ssl-cert=certificates/dev-local/cert.crt --ssl-key=certificates/dev-local/cert.key --port=4201",
    "start:homolog": "ng serve -c=homolog --host=appdevlocal.namoa.io --ssl=true --ssl-cert=certificates/dev-local/cert.crt --ssl-key=certificates/dev-local/cert.key --open",
    "start:prod": "ng serve -c=production --host=appdevlocal.namoa.io --ssl=true --ssl-cert=certificates/dev-local/cert.crt --ssl-key=certificates/dev-local/cert.key --open",
    "build:prod": "ng build --configuration production && node ./generate-white-index.js",
    "build:homolog": "ng build -c=production,homolog && node ./generate-white-index.js",
    "build:dev": "ng build -c=dev && node ./generate-white-index.js",
    "build:devlocal": "ng build -c=devlocal",
    "preview-whitelabel": "bash -c 'angular-http-server --path dist/webapp/browser --https --cert certificates/dev-local/cert.crt --key certificates/dev-local/cert.key --host $0.namoa.io --rootFile index-$0.html --open'",
    "test": "jest --no-cache",
    "test:dev": "jest --watch",
    "lint": "ng lint",
    "e2e": "ng e2e"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.2.1",
    "@angular/cdk": "^19.2.2",
    "@angular/cdk-experimental": "^19.2.2",
    "@angular/common": "^19.2.1",
    "@angular/compiler": "^19.2.1",
    "@angular/core": "^19.2.1",
    "@angular/forms": "^19.2.1",
    "@angular/google-maps": "^19.2.2",
    "@angular/localize": "^19.2.1",
    "@angular/material": "^19.2.2",
    "@angular/material-moment-adapter": "^19.2.2",
    "@angular/platform-browser": "^19.2.1",
    "@angular/platform-browser-dynamic": "^19.2.1",
    "@angular/router": "^19.2.1",
    "@ngx-formly/core": "^6.0.0",
    "@ngx-formly/material": "^6.0.0",
    "@types/lodash": "^4.14.162",
    "material-icons": "^1.12.0",
    "ng-animate": "^0.3.4",
    "ngx-webcam": "^0.4.1",
    "qrcode": "^1.5.4",
    "roboto-fontface": "^0.10.0",
    "rxjs": "^7.5.5",
    "tslib": "^2.0.0",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.2.1",
    "@angular-eslint/builder": "19.2.1",
    "@angular-eslint/eslint-plugin": "19.2.1",
    "@angular-eslint/eslint-plugin-template": "19.2.1",
    "@angular-eslint/schematics": "19.2.1",
    "@angular-eslint/template-parser": "19.2.1",
    "@angular/cli": "^19.2.1",
    "@angular/compiler-cli": "^19.2.1",
    "@jsverse/transloco": "^7.4.3",
    "@jsverse/transloco-messageformat": "^7.0.1",
    "@ngx-formly/schematics": "^6.1.8",
    "@stylistic/eslint-plugin": "^4.2.0",
    "@stylistic/eslint-plugin-ts": "^4.2.0",
    "@types/jasmine": "^5.1.4",
    "@types/jasminewd2": "^2.0.13",
    "@types/jest": "^29.5.12",
    "@types/md5": "^2.2.0",
    "@types/node": "^12.11.1",
    "@types/qrcode": "^1.5.5",
    "@typescript-eslint/eslint-plugin": "^8.26.1",
    "@typescript-eslint/parser": "^8.26.1",
    "angular-eslint": "^19.2.1",
    "angular-fusioncharts": "^4.1.1",
    "eslint": "^9.22.0",
    "fusioncharts": "^3.18.0",
    "husky": "^5.0.6",
    "is-ci": "^2.0.0",
    "jest": "^29.7.0",
    "jest-canvas-mock": "^2.5.2",
    "jest-preset-angular": "^14.5.3",
    "ngx-mask": "^19.0.6",
    "ts-md5": "^1.2.7",
    "ts-node": "~8.3.0",
    "typescript": "5.6"
  }
}
