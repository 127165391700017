import { Observable, Subscription, combineLatest } from 'rxjs';
import { AutoUnsubscribeOnDestroy, getObservableAndAutoUnsubscribe } from 'src/app/helpers/auto-unsubscribe';
import { SelectVirtualScrollAddNewItem, SelectVirtualScrollDataProperties, SelectVirtualScrollEditItem, SelectVirtualScrollSearchItem } from './select-virtual-scroll.component';
import { AbstractControl } from '@angular/forms';

export function selectVirtualScrollLoadData(
	selectData: SelectVirtualScrollObj,
	request: (filters, params) => Observable<any>,
	filters = null,
	pageLimit: number,
	keyData = 'data',
	setModelWhenOnlyOneItem = false,
	component: AutoUnsubscribeOnDestroy
): Promise<boolean>{

	const promise = new Promise<boolean>((promiseResolve, promiseReject) => {

		if(selectData.subscription){
			selectData.subscription.unsubscribe();
		}

		if(!selectData.keepDisabledAfterLoad){
			if(selectData.formControl){
				selectData.formControl.enable();
			}else{
				selectData.disabled = false;
			}
		}
		selectData.ready = false;

		const selectSubscription = request(
			filters,
			{
				params: {
					limit: pageLimit,
					page: 1
				}
			}
		).subscribe({
			next: result => {

				selectVirtualScrollLoadDataAllPages(
					selectData,
					new Observable(subscriber => {
						subscriber.next({
							body: result.body
						});
					}),
					params => request(filters, {params}),
					pageLimit,
					result.body.links?.totalPages || 1,
					keyData,
					setModelWhenOnlyOneItem,
					component,
					promiseResolve,
					promiseReject
				);

			},
			error: () => promiseReject(false)
		});

		selectData.subscription = selectSubscription;

		if(component){
			getObservableAndAutoUnsubscribe(component, selectSubscription);
		}

	});

	promise
		.then(result => {})
		.catch(error => {});

	return promise;

}

function getCompositeId(
	selectData: SelectVirtualScrollObj,
	item: any
){
	return selectData.compositeId.keys.reduce(
		(accumulator: string, currentValue: string) =>
			accumulator + (accumulator ? ',' : '' ) + item[currentValue],
		''
	);
}

function setFormControlInitialValue(
	selectData: SelectVirtualScrollObj,
	item: any
){
	if(selectData.formControl && selectData.compositeIdItemSelected(item)){
		selectData.formControl.setValue(item.compositeId);
	}
}

function selectVirtualScrollLoadDataAllPages(
	selectData: SelectVirtualScrollObj,
	firstPage: Observable<any>,
	request: (params) => Observable<any>,
	pageLimit: number,
	totalPages: number,
	keyData = 'data',
	setFormControlWhenOnlyOneItem: boolean,
	component: AutoUnsubscribeOnDestroy,
	promiseResolve?: (value: boolean | PromiseLike<boolean>) => void,
	promiseReject?: (value: boolean | PromiseLike<boolean>) => void
){

	const allPages: Observable<any>[] = [firstPage];

	for(let i=2; i<=totalPages; i++){
		allPages.push(
			request({
				limit: pageLimit,
				page: i
			})
		);
	}

	selectData.subscription = combineLatest(allPages).subscribe({
		next: allPagesResult => {
			const data = allPagesResult.reduce((prev, current) => {
				prev.push(...(current.body[keyData] || []));
				return prev;
			}, []);
			selectData.data.splice(0);
			selectData.data.push(...data);
			selectData.ready = true;

			if(selectData.compositeId){
				selectData.data.map(item => {
					item.compositeId = getCompositeId(selectData, item);
					setFormControlInitialValue(selectData, item);
				});
			}

			if(
				setFormControlWhenOnlyOneItem
				&&
				selectData.data.length === 1
			){
				selectData.formControl.setValue(selectData.data[0][selectData.dataProperties.id]);
				// this.onSelectVirtualScrollValueChange(null, selectData);
			}

			if(promiseResolve){
				promiseResolve(true);
			}

		},
		error: () => {
			if(promiseReject){
				promiseReject(false);
			}
		}
	});

	if(component){
		getObservableAndAutoUnsubscribe(
			component,
			selectData.subscription
		);
	}


}

export function selectVirtualScrollSelectCompositeIdItem(
	selectData: SelectVirtualScrollObj
){
	selectData.formControl.setValue(null);
	selectData.data?.forEach(item => setFormControlInitialValue(selectData, item));
}

export interface SelectVirtualScrollObj<T = any> {
	dataProperties: SelectVirtualScrollDataProperties<T>;
	subscription: Subscription;
	disabled?: boolean;
	keepDisabledAfterLoad?: boolean;
	ready: boolean;
	data: T[];
	modelKey?: string;
	formControl?: AbstractControl<any, any>;
	compositeId?: {
		keys: (keyof Omit<T, 'compositeId'>)[];
	};
	compositeIdItemSelected?: (item: T) => boolean;
	addNewItem?: SelectVirtualScrollAddNewItem<T>;
	editItem?: SelectVirtualScrollEditItem<T>;
	searchItem?: SelectVirtualScrollSearchItem<T>;
}
